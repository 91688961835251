<template>
  <div class="device-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-select v-model="listfilter.unitid" filterable remote reserve-keyword default-first-option clearable
              placeholder="请选择运维单位" size="medium" :remote-method="getMaintainList" :loading="getMaintainLoading"
              @change="onSearch">
              <el-option v-for="item in maintainSelect" :key="item.Id" :label="item.Name" :value="item.Id">
              </el-option>
            </el-select>


            <el-input placeholder="联系人、电话" style="width: 300px; margin-left: 10px" size="medium" class="form-input-group"
              v-model="listfilter.fname" @keyup.enter.native="onSearch">
              <el-button slot="append" type="primary" icon="el-icon-search" @click="onSearch">查询</el-button>
            </el-input>
          </el-form>
        </div>
        <div class="action-right">
          <router-link to="/company/maintainuser/add">
            <el-button type="primary" icon="el-icon-circle-plus-outline" size="medium">新增</el-button>
          </router-link>
        </div>
      </div>
    </div>

    <el-table class="list-table" ref="multipleTable" :data="listData" tooltip-effect="dark" style="width: 100%"
      :stripe="true" :border="false" v-loading="tableLoading" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column label="人员名称" prop="Name"></el-table-column>
      <el-table-column label="头像">
        <template slot-scope="scope">
          <el-avatar v-if="scope.row.HeadIcon" shape="square" :size="32" :src="scope.row.HeadIcon"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column label="联系电话" prop="Mobile"></el-table-column>
      <el-table-column label="职位" prop="Position"></el-table-column>
      <el-table-column label="邮箱" prop="Email"></el-table-column>
      <el-table-column label="状态" prop="EnabledMark">
        <template slot-scope="scope">
          <el-tag v-if="!scope.row.EnabledMark" effect="dark" size="small">启用</el-tag>
          <el-tag v-if="scope.row.EnabledMark" type="danger" effect="dark" size="small">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button type="success" icon="el-icon-edit" size="mini" @click="onListEdit(scope.row)">修改</el-button>
          <el-button type="warning" icon="el-icon-error" size="mini" style="margin-left: 10px"
            @click="onListDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" class="list-pagination"
      :page-size="page.pagesize" hide-on-single-page :total="page.total" :current-page.sync="page.pageindex"
      @current-change="onPageChange" />

  </div>
</template>
<script>
import { getMaintainUserList, delMaintainUser, getMaintainList } from "@/api/base";
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,
      getMaintainLoading: false,
      maintainSelect: [],
      listfilter: {
        fname: "",
        unitid: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },
    };
  },
  props: {
    unitid: String
  },
  components: {
    pagination,
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getMaintainList(value) {
      this.getMaintainLoading = true;
      getMaintainList(1, 100, {
        fname: value
      }
      ).then((resdata) => {
        this.getMaintainLoading = false;
        if (resdata.code == 0) {
          this.maintainSelect = resdata.data.data;
        }
        this.listfilter.unitid = this.unitid ? this.unitid * 1 : '';
        this.getListData();
      });
    },
    onSearch() {
      this.page.pageindex = 1;
      this.getListData();
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getMaintainUserList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onListEdit(item) {
      this.$store.dispatch('global/setMaintainUserInfo', item);
      this.$router.push('/company/maintainuser/edit?id=' + item.Id);
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delMaintainUser(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => { });
    },
  },
  created() {
    this.tableLoading = true;
    this.getMaintainList('');
  },
};
</script>